export default [
  {
    header: 'Dashboard',
  },
  {
    title: 'Dashboard',
    route: 'addQrcodes',
    icon: 'HomeIcon',
  },  

  // {
  //   title: 'Dashboard',
  //   route: 'dashboard-analytics',
  //   icon: 'HomeIcon',
  // },  
  // {
  //   title: 'Dashboard',
  //   route: 'dashboard-analytics',
  //   icon: 'HomeIcon',
  // },  


  // {
  //   title: 'Analytics',
  //   route: '',
  //   icon: 'BarChart2Icon',
  // },
  // {
  //   title: 'Members',
  //   route: '',
  //   icon: 'UsersIcon',
  // },
    
]
